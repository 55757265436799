import moment from "moment";

export default class Stats {

  static getLabels(date){
    let daysInMonth = date.daysInMonth()
    let labels = []
    for(var i=1; i <= daysInMonth; i++){
      labels.push(date.date(i).format("DD MMM"))
    }
    return labels
  }

  static getSourceData(data, type){
    let current = data.source_stats.current_period[type].map(stat => stat.total);
    let previous = data.source_stats.previous_period[type].map(stat => stat.total);
    return [{data: current, color: '#6610f2', borderDash: []}, {data: previous, color: '#87f5fb', borderDash: [5, 5]}]
  }

  static getWidgetData(data, type){
    let stat = data.widget_stats[type].map(stat => stat.total)
    return [{data: stat, color: '#6610f2'}]
  }

  static getWidgetImpressionsData(data, type){
    let actualImpressions = data.widget_stats[type].map(stat => stat.total_actual_impressions)

    return [{label: 'Actual Impressions', data: actualImpressions, color: '#6610f2'}]
  }

  static getWidgetConversionData(data){
    let stat = data.widget_stats.conversion_rates.map(stat => stat.total)
    return [{data: stat, color: '#6610f2', borderDash: []}]
  }

  static getWidgetTypeConversion(data){
    let companies = data.widget_stats.conversion_rates_by_type.company_rates.map(stat => stat.total);
    let jobs = data.widget_stats.conversion_rates_by_type.job_rates.map(stat => stat.total);
    let widgets = data.widget_stats.conversion_rates_by_type.article_rates.map(stat => stat.total);
    return [{data: companies, color: '#6610f2', borderDash: []}, {data: jobs, color: '#87f5fb', borderDash: [5, 5]}]
  }

  static getWidgetStyleConversion(data, style){
    let horizontal = data.widget_stats.conversion_rates_by_style[`horizontal_${style}`].map(stat => stat.total);
    let masonry = data.widget_stats.conversion_rates_by_style[`masonry_${style}`].map(stat => stat.total);
    let sidebar = data.widget_stats.conversion_rates_by_style[`sidebar_${style}`].map(stat => stat.total);
    return [{label: 'Horizontal', data: horizontal, color: '#6610f2'}, {label: 'Masonry', data: masonry, color: '#87f5fb'}, {label: 'Sidebar', data: sidebar, color: '#eb4adf'}]
  }

}