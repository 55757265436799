import React from 'react'
import {withRouter} from 'react-router-dom'

import AuthManager from '../../../utils/AuthManager'

const LOGO = require('../../../assets/media/amply_logo.png')

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: `${AuthManager.currentUser.user.first_name} ${AuthManager.currentUser.user.last_name}`,
      email: `${AuthManager.currentUser.user.email}`,
      show: false
    }
  }

  componentDidMount(){
    this._updateActive()
  }

  componentWillUnmount() {
    // Event.off('USER_UPDATED');
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._updateActive();
    }
  }

  _updateActive() {
    let pathname = this.props.location.pathname
    // MENU_ITEMS.forEach(menuItem => {
    //   if(menuItem.url == pathname){
    //     this._setActive(menuItem.title)
    //   }
    // })
  }

  _setActive(title){
    this.setState({ title })
  }

  _onLogoutPressed() {
    AuthManager.logout()
    .then(() => {
      this.props.history.push('/login')
    })
  }

  render() {
    let {
      username,
      email,
      show
    } = this.state

    let className = show ? 'show' : ''

    return (
      <div id="kt_header" className="header align-items-stretch">
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <a href={window.General.Site}>
              <img alt="Logo" src={LOGO} className="h-20px"/>
            </a>
          </div>
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div className="d-flex align-items-stretch" id="kt_header_nav"/>
            <div className="d-flex align-items-stretch flex-shrink-0">
              <div className="d-flex align-items-stretch flex-shrink-0">
                <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                  <div
                    className="cursor-pointer symbol symbol-30px symbol-md-40px menu-dropdown" data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
                    onClick={() => this.setState({show: !show})}
                  >
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.7" x="0.5" y="0.5" width="39" height="39" rx="8.5" stroke="#E4E6EF"></rect>
                      <path
                        d="M14.2484 21.8902C15.3358 20.0959 17.2815 19 19.3796 19H20.6204C22.7185 19 24.6642 20.0959 25.7516 21.8902L27.1596 24.2134C27.9674 25.5462 27.0078 27.25 25.4492 27.25H14.5508C12.9922 27.25 12.0326 25.5462 12.8404 24.2134L14.2484 21.8902Z"
                        fill="#5E6278"></path>
                      <rect opacity="0.3" x="16" y="10.75" width="8" height="7.33333" rx="3.66667"
                            fill="#5E6278"></rect>
                    </svg>
                  </div>
                  <div
                    className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px ${className}`}
                    data-kt-menu="true" data-popper-placement="bottom-end" style={{zIndex: 105, position: 'fixed', inset: '0px 0px auto auto', margin: 0, transform: 'translate3d(-30px, 65px, 0px)'}}
                  >
                    <div className="menu-item px-3">
                      <div className="menu-content d-flex align-items-center px-3">
                        <div className="d-flex flex-column">
                          <div className="fw-bolder d-flex align-items-center fs-5">
                            {username}
                          </div>
                          <a href="#" className="fw-bold text-muted text-hover-primary fs-7">{email}</a>
                        </div>
                      </div>
                    </div>
                    <div className="separator my-2"></div>
                    <div className="menu-item">
                      <a href="javascript:;" className="menu-link px-5" onClick={() => this._onLogoutPressed()}>Sign Out</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)
