import React from "react"
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import Card from "./components/common/Card";
import LineChart from "./components/chart/LineChart";
import BarChart from "./components/chart/BarChart";
import StatCard from "./components/common/StatCard";

import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Stats from "../utils/Stats";
import Event from "../utils/Event";
import General from "../utils/General";

const TYPES = [
  {
    label: "Jobs Widget",
    value: "jobs"
  },
  {
    label: "Companies Widget",
    value: "companies"
  }
]

export default class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      type: 'last_28_days',
      labels: Stats.getLabels(moment()),
      stats: null,
      style: 'jobs',
      selectedDate: moment()
    }
    this._setUpEvents()
  }

  componentDidMount() {
    this._loadStats()
  }

  _setUpEvents(){
    Event.on('DATE_CHANGE', (date) => {
      this.setState({selectedDate: date}, () => this._loadStats())
    })
  }

  _loadStats(){
    Backend.getStats(this.state.selectedDate.format("MM,YYYY")).then(stats => {
      this.setState({
        stats,
        labels: Stats.getLabels(this.state.selectedDate)
      }, () => {
        Event.emit('LOADED_STATS', stats)
      })
    }).catch(e => {
      Notify.error(e.message)
    })
  }

  render() {
    let {
      stats,
      labels,
      style
    } = this.state

    if(!stats) return null

    return (
      <>
        <div className="row gy-5 g-xl-10">
          <div className="col-xl-6">
            <Card
              cardClassName="card card-xl-stretch mb-5 mb-xl-8"
              title="Job Impressions"
              legend={[{label: "Current Period"}, {label: "Previous Period"}]}
            >
              <LineChart
                minimal={false}
                labels={labels}
                data={Stats.getSourceData(stats, 'job_impressions')}
              />
            </Card>
          </div>
          <div className="col-xl-6">
            <Card
              cardClassName="card card-xl-stretch mb-5 mb-xl-8"
              title="Job Views"
              legend={[{label: "Current Period"}, {label: "Previous Period"}]}
            >
              <LineChart
                minimal={false}
                labels={labels}
                data={Stats.getSourceData(stats, 'job_views')}
              />
            </Card>
          </div>
          <div className="col-xl-6">
            <Card
              cardClassName="card card-xl-stretch mb-xl-8"
              title="Company Impressions"
              legend={[{label: "Current Period"}, {label: "Previous Period"}]}
            >
              <LineChart
                minimal={false}
                labels={labels}
                data={Stats.getSourceData(stats, 'company_impressions')}
              />
            </Card>
          </div>
          <div className="col-xl-6">
            <Card
              cardClassName="card card-xl-stretch mb-5 mb-xl-8"
              title="Company Views"
              legend={[{label: "Current Period"}, {label: "Previous Period"}]}
            >
              <LineChart
                minimal={false}
                labels={labels}
                data={Stats.getSourceData(stats, 'company_views')}
              />
            </Card>
          </div>
        </div>

        <div className="row gy-5 g-xl-10">
          <div className="col-xl-12">
            <Card
              cardClassName="card mb-5 mb-xl-8"
              bodyClassName={"card-body py-3"}
              title="Widgets"
            >
              <div className="row g-5 g-xl-8">
                <div className="col-xl-3">
                  <StatCard
                    title={"Total Clicks"}
                    amount={stats.widget_stats.total_clicks}
                    background={"bg-primary"}
                  />
                </div>
                <div className="col-xl-3">
                  <StatCard
                    title={"Total Impressions"}
                    amount={stats.widget_stats.total_impressions}
                    background={"bg-success"}
                  />
                </div>
                <div className="col-xl-3">
                  <StatCard
                    title={"Actual Impressions"}
                    amount={stats.widget_stats.total_actual_impressions}
                    background={"bg-primary"}
                  />
                </div>
                <div className="col-xl-3">
                  <StatCard
                    title={"Conversion Rate"}
                    amount={stats.widget_stats.conversion_rate}
                    background={"bg-success"}
                  />
                </div>
                <div className="col-xl-6">
                  <Card
                    cardClassName="card card-xl-stretch border mb-xl-8"
                    title="Widget Clicks"
                  >
                    <BarChart
                      minimal={false}
                      labels={labels}
                      data={Stats.getWidgetData(stats, 'clicks')}
                    />
                  </Card>
                </div>
                <div className="col-xl-6">
                  <Card
                    cardClassName="card card-xl-stretch border mb-xl-8"
                    title="Widget Impressions"
                  >
                    <BarChart
                      minimal={false}
                      labels={labels}
                      data={Stats.getWidgetImpressionsData(stats, 'impressions')}
                    />
                  </Card>
                </div>
                <div className="col-xl-6">
                  <Card
                    cardClassName="card card-xl-stretch border mb-xl-8"
                    title="Widget Conversion Rate by Style (%)"
                    options={TYPES}
                    selectedOption={style}
                    legend={[{label: "Horizontal"}, {label: "Masonry"}, {label: "Sidebar"}]}
                    onChange={style => this.setState({style})}
                  >
                    <BarChart
                      minimal={false}
                      labels={labels}
                      data={Stats.getWidgetStyleConversion(stats, style)}
                    />
                  </Card>
                </div>
                <div className="col-xl-6">
                  <Card
                    cardClassName="card card-xl-stretch border mb-xl-8"
                    title="Widget Conversion Rate (%)"
                  >
                    <LineChart
                      minimal={false}
                      labels={labels}
                      data={Stats.getWidgetConversionData(stats)}
                    />
                  </Card>
                </div>
                <div className="col-xl-12">
                  <Card
                    cardClassName="card card-xl-stretch border mb-xl-8"
                    title="Widget Conversion Rate by Type (%)"
                    legend={[{label: "Companies"}, {label: "Jobs"}]}
                  >
                    <LineChart
                      minimal={false}
                      labels={labels}
                      data={Stats.getWidgetTypeConversion(stats)}
                    />
                  </Card>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </>
    )
  }
}
