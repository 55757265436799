import React from "react"
import General from "../../../utils/General";
import Select from "./Select";

export default class Card extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      legend: props.legend,
      options: props.options,
      selectedOption: props.selectedOption,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      legend,
      options,
      selectedOption,
    } = this.state

    return (
      <div className={this.props.cardClassName}>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-3">
              {title}
            </span>
            {
              legend &&
              <span className="text-muted fw-bold fs-8">
                {
                  legend.map((option, index) => (
                    <><span className={`legend-${index + 1}`}/> {option.label}</>
                  ))
                }
              </span>
            }
          </h3>
          {
            options &&
            <>
              <div className="card-toolbar">
                <Select
                  value={options.find(option => option.value === selectedOption)}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  name="status"
                  placeholder="Please Select An Option"
                  onChange={option => this.props.onChange(option.value)}
                  options={options}
                />
              </div>
            </>
          }
        </div>
        <div className={this.props.bodyClassName} style={{position: 'relative'}}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Card.defaultProps = {
  cardClassName: "card mb-5",
  bodyClassName: "card-body",
  visible: true,
  renderHead: () => null,
  renderToolbar: () => null,
}
