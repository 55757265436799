import React from "react"

export default class StatCard extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      amount: props.amount,
      background: props.background,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      amount,
      background,
    } = this.state

    return (
      <a href="#" className={`card ${background} hoverable card-xl-stretch mb-xl-8`}>
        <div className="card-body">
          <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect x="8" y="9" width="3" height="10" rx="1.5" fill="black"/>
              <rect opacity="0.5" x="13" y="5" width="3" height="14" rx="1.5" fill="black"/>
              <rect x="18" y="11" width="3" height="8" rx="1.5" fill="black"/>
              <rect x="3" y="13" width="3" height="6" rx="1.5" fill="black"/>
            </svg>
          </span>
          <div className="text-white fw-bolder fs-2 mb-2 mt-5">{amount}</div>
          <div className="fw-bold text-white">{title}</div>
        </div>
      </a>
    )
  }
}

StatCard.defaultProps = {

}
