import React from "react"
import Stats from "../../../utils/Stats";
import AuthManager from "../../../utils/AuthManager";
import {Link} from "react-router-dom";
import Event from "../../../utils/Event";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.getCurrentUser(),
      stats: {total_job_impressions: 0, total_job_views: 0}
    }
    this._setUpEvents()
  }

  _setUpEvents(){
    Event.on('LOADED_STATS', (stats) => {
      this.setState({'stats': stats})
    })
  }

  _changeDate(value){
    this.setState({selectedDate: value})
  }

  _loadStats(){
    Event.emit('DATE_CHANGE', this.state.selectedDate)
  }

  render() {
    let {
      stats,
      currentUser,
      selectedDate
    } = this.state

    return (
      <div className="card mb-5 mb-xl-10">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img src={currentUser.channel.logo} alt="image"/>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <p className="text-gray-900 fs-2 fw-bolder me-1">{currentUser.channel.name}</p>
                  </div>
                  <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">

                  </div>
                </div>
                <div className="d-flex my-4">
                  <div className="d-flex align-items-center ms-1 ms-lg-3">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        variant={"inline"}
                        openTo={"month"}
                        views={["year", "month"]}
                        onChange={value => this._changeDate(value)}
                        value={selectedDate}
                      />
                    </MuiPickersUtilsProvider>
                    <button
                      className="btn btn-sm btn-primary ms-3"
                      onClick={() => this._loadStats()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-wrap flex-stack">
                <div className="d-flex flex-column flex-grow-1 pe-8">
                  <div className="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div
                          className="fs-2 fw-bolder counted"
                          data-kt-countup="true"
                          data-kt-countup-value={stats.total_job_impressions}
                        >
                          {stats.total_job_impressions}
                        </div>
                      </div>
                      <div className="fw-bold fs-6 text-gray-400">Total Job Impressions</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div
                          className="fs-2 fw-bolder counted"
                          data-kt-countup="true"
                          data-kt-countup-value={stats.total_job_views}
                        >
                          {stats.total_job_views}
                        </div>
                      </div>
                      <div className="fw-bold fs-6 text-gray-400">Total Job Views</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">

                </div>
              </div>
            </div>
          </div>
          <div className="d-flex overflow-auto h-55px">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item">
                <Link to={'/'} className="nav-link text-active-primary me-6 active">Performance</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
