import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Header from "./components/structure/Header";
import Footer from "./components/structure/Footer";
import Navigation from "./components/structure/Navigation";

import Dashboard from "./Dashboard";


export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <Header/>
              <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div className="container-xxl" id="kt_content_container">
                    <Navigation/>
                    <Switch>
                      <Route exact path="/" component={Dashboard} />
                    </Switch>
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
      </>
    )
  }

}