import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'
import moment from "moment";

export default class Backend {

  static getStats(date){
    return FetchHelper.get(`${window.Api.Stats}?date=${date}`)
  }

}
