import React, { Component } from 'react';

import General from '../../../utils/General'

import {Line} from 'react-chartjs-2';

export default class LineChart extends Component  {

  constructor(props){
    super(props);
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    let labels = props.labels || props.data.map(point => "")
    return {
      data: this._getData(props.data, labels, props.color),
      minimal: props.minimal,
    }
  }

  _getData(data, labels, color){
    let border = 2

    let dataset = data.map(stat => {
      return {
        label: "",
        lineTension: 0,
        borderColor: stat.color,
        borderWidth: border,
        backgroundColor: this.props.backgroundColor,
        borderDash: stat.borderDash,
        pointRadius: 2,
        pointHoverRadius: 2,
        pointHoverBorderWidth: 2,
        pointBackgroundColor: stat.color,
        pointBorderColor: stat.color,
        pointHoverBackgroundColor: stat.color,
        pointHoverBorderColor: stat.color,
        fill: this.props.backgroundColor != null,
        data: stat.data,
      }
    })

    return  {
      labels,
      datasets: dataset
    }
  }

  render(){
    let {
      data,
      minimal
    } = this.state

    let options = { ...this.props.options }

    options.scales.xAxes[0] = {
      ...options.scales.xAxes[0],
      display: !minimal,
      gridLines: !minimal,
    }

    options.scales.yAxes[0] = {
      ...options.scales.yAxes[0],
      display: !minimal,
      gridLines: !minimal,

    }

    let props = {}

    if(minimal){
      props.width = 60
      props.height = 30
      options.maintainAspectRatio = false
    }

    return (
      <Line
        key={JSON.stringify(data)}
        { ...props }
        data={data}
        options={options}
      />
    )
  }
}

LineChart.defaultProps = {
  minimal: true,
  options: {
    title: {
      display: false,
    },
    tooltips: {
      enabled: true,
      intersect: false,
      mode: 'x',
      xPadding: 10,
      yPadding: 10,
      caretPadding: 10,
      backgroundColor: '#fff',
      titleFontSize: 15,
      titleFontColor: '#555555',
      titleSpacing: 3,
      titleAlign: 'center',
      bodyFontColor: '#55555',
      bodySpacing: 7,
      bodyAlign: 'center',
      borderWidth: 2,
      borderColor: '#e5e5e5',
      displayColors: true,
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: false
      }
    },
    responsive: true,
    maintainAspectRatio: true,
    hover: {
      mode: 'index'
    },
    scales: {
      xAxes: [{
        display: false,
        gridLines: false,
        scaleLabel: {
          display: true,
          labelString: ''
        },
        ticks: {
          padding: 10
        }
      }],
      yAxes: [{
        display: false,
        gridLines: false,
        scaleLabel: {
          display: true,
          labelString: ''
        },
        ticks: {
          beginAtZero: true,
          callback: function(value) {if (value % 1 === 0) {return value;}},
          padding: 10
        }
      }]
    },

    elements: {
      point: {
        radius: 4,
        borderWidth: 12
      },
    },

    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 5,
        bottom: 5
      }
    }
  }
}

const styles = {
  container: {
    width: 100,
    height: 50
  },
  canvas: {
    display: 'block',
    width: 100,
    height: 50
  }
}